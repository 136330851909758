import React, { FC } from 'react';
import { useId } from 'react-id-generator';
import { graphql } from 'gatsby';

import Layout from 'layout/Layout';
import HomeBanner from 'components/HomeBanner';
import HomeBannerCarousel from 'components/HomeBannerCarousel';
import { bodyAdapter } from 'utils/bodyAdapter';
import BodyRenderer from 'utils/bodyRenderer';

import { HomepageProps } from './models';

const HomePage: FC<{
  data: HomepageProps;
  pageContext: { pagePathname: string };
}> = ({
  data: {
    homePage: { seo, langProps, mainBanner, body, pageHeading },
  },
  pageContext: { pagePathname },
}) => (
  <Layout
    {...{
      seo,
      langProps,
      pagePathname,
      additionalSchema: 'Organization',
    }}
  >
    <h1 className="visually-hidden">{pageHeading}</h1>
    {mainBanner.length === 1 ? (
      <HomeBanner {...mainBanner[0]} />
    ) : (
      <HomeBannerCarousel carouselItems={mainBanner} />
    )}
    {bodyAdapter(body).map((component) => {
      const [key] = useId();

      return <BodyRenderer {...component} key={key} />;
    })}
  </Layout>
);

export const query = graphql`
  query($lang: String) {
    homePage(langProps: { lang: { eq: $lang } }) {
      seo {
        ...SEOStructureFragment
      }
      langProps {
        urls {
          ...languageUrls
        }
        lang
      }
      pageHeading
      mainBanner {
        ...HomeBannerFragment
      }

      body {
        homeBanner {
          ...HomeBannerFragment
        }
        articleCategoryListingListing {
          ...ListingFragment
        }
        articleCategoryListing {
          ...ListingFragment
        }
        productsCategoryListing {
          ...ListingFragment
        }
        teaser {
          ...TeaserFragment
        }
        fullWidthTeaser {
          title
          alt
          cta {
            url
            name
          }
          ctaAriaLabel
          description
          image {
            ...ImageFragment
          }
        }

        stainsListing {
          ...ListingFragment
        }
        expertiseListing {
          ...ListingFragment
        }
        articlePromoListing {
          ...ListingFragment
        }
        productsListing {
          ...ListingFragment
        }
        newsletterNodePicker {
          ...NewsletterBannerFragment
        }
        textbox {
          ...TextboxFragment
        }
      }
    }
  }
`;

export default HomePage;
